import * as EmailValidator from "email-validator";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React, { useContext } from "react";
import TextFormikField from "../components/FormikFields/TextFormikField";
import { Context } from "../components/store";
import WaitingAnimation from "../components/WaitingAnimation";
import { addError, addSuccess, useNotificationStore } from "../utils/errors";
import { urlService } from "../_services/urlService";

// markup
const Page = () => {
  type ValueType = {
    name?: string;
    email?: string;
    subject?: string;
    text?: string;
  };

  type ErrorsType = {
    name?: string;
    email?: string;
    subject?: string;
    text?: string;
  };

  return (
    <div>
      {/* <PrivateRoute component={ VertragList }/> */}
      {/* <VertragList/> */}
      <h2>Kontakt</h2>
      Sie können uns hier ganz einfach eine Nachricht schicken. Alternativ gerne auch per Email an{" "}
      <a href="mailto:kontakt@rap-tool.de">kontakt@rap-tool.de</a>.
      <Formik
        initialValues={{} as ValueType}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          let url = urlService.getUrl(`/api/notification/send`);
          urlService
            .getAxios()
            .post(url, values)
            .then((res) => {
              if (res.data) {
                addSuccess(res.data.message);
              } else {
                // TODO: Do this!
                console.log("No data fetched from filecontent Rest-Endpoint");
              }
              setSubmitting(false);
            })
            .catch((err) => {
              // TODO: Diese Fehler sollten in einer Standard-Komponente verarbeitet werden. Das ist immer das gleiche...
              if (err.response && err.response.data && err.response.data.displayMessage) {
                addError(err.response.data.displayMessage);
              } else if (err.response && err.response.status === 504) {
                addError("Der Server konnte nicht erreicht werden. Bitte prüfen Sie Ihre Internetverbindung.");
              } else {
                addError(err.message);
              }
              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {} as ErrorsType;
          if (!values.name) {
            errors.name = "Bitte geben Sie Ihren Namen ein";
          }
          if (!values.email) {
            errors.email = "Wir benötigen Ihre Emailadresse um Ihnen antworten zu können";
          } else if (!EmailValidator.validate(values.email)) {
            errors.email = "Bitte geben Sie eine gültige Emailadresse ein";
          }
          if (!values.subject) {
            errors.subject = "Bitte geben Sie einen Betreff ein";
          }
          if (!values.text) {
            errors.text = "Bitte geben Sie einen Text ein";
          }
          return errors;
        }}
      >
        {({ errors, touched, isSubmitting, values, handleChange, setTouched }) => (
          <Form style={{ maxWidth: "400px", margin: "auto" }}>
            {/* This all has weird margin elements but mui is really annoying, this way */}
            <div className="text-center">{isSubmitting ? <WaitingAnimation /> : null}</div>
            <div className="mt-2 flex">
              <div className="flex-1">
                <Field name="name" disabled={isSubmitting} component={TextFormikField} className="w-full" placeholder="Ihr Name" />
              </div>
              <div className="ml-2 flex-1">
                <Field name="email" disabled={isSubmitting} component={TextFormikField} className="w-full" placeholder="Emailadresse" />
              </div>
              <div className="ml-2 hidden sm:inline-block flex-0">
                <button className="button button-primary" type="submit" disabled={isSubmitting || _.some(errors)}>
                  absenden
                </button>
              </div>
            </div>
            <div className="mt-2">
              <Field name="subject" disabled={isSubmitting} component={TextFormikField} className="w-full" placeholder="Betreff" />
            </div>
            <div className="mt-2">
              <Field
                name="text"
                disabled={isSubmitting}
                component={TextFormikField}
                multiline
                minRows={3}
                className="w-full"
                placeholder="Nachrichtentext"
              />
            </div>
            <div className="text-right mt-2 sm:hidden">
              {" "}
              <button className="button button-primary flex-0" type="submit" disabled={isSubmitting || _.some(errors)}>
                absenden
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Page;
